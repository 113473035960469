import {
  Box,
  createStyles,
  Grid,
  Link,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../reducers";
import React, { useEffect, useState } from "react";
import * as EventActions from "../../actions/events";
import { Section, ISection } from "../../model/section";
import { useHistory } from "react-router";
import intl from "react-intl-universal";
import EventCarousel from "../../components/EventCarousel";
import HeroCarousel from "../../components/HeroCarousel";
import EventCard from "../../components/EventCard";
import { isMobile } from "react-device-detect";
import useFavorites from "../../hooks/useFavorites";
import LoadingView from "../../components/LoadingView";
import ErrorRetryView from "../../components/ErrorRetryView";
import { useAppTheme } from "../../hooks/useAppTheme";
import { IEvent } from "../../model/event";
import LocationBar from "../../components/LocationBar";
import { useAppLocation } from "../../hooks/useAppLocation";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import useDesktopAdSlot from "../../hooks/useDesktopAdSlot";
import useMobileAdSlot from "../../hooks/useMobileAdSlot";
import {
  CategorySectionGridItem,
  CategorySectionGridItemWithAd,
} from "../../components/CategorySectionGridItem";
import { useUser } from "../../hooks/useUser";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: theme.carousel.heroBackground,
      paddingBottom: 100,
      [theme.breakpoints.down("md")]: {
        marginBottom: 0,
      },
    },
    heroSection: {
      paddingBottom: theme.authpageStyle === "flat" ? 0 : 15,
      [theme.breakpoints.down("md")]: {
        paddingTop: 15,
        paddingBottom: theme.authpageStyle === "flat" ? 0 : 15,
      },
    },
    categorySection: {
      borderRadius: 15,
      backgroundColor: theme.palette.background.default,
      paddingBottom: 30,
      paddingTop: 40,
      marginBottom: 20,
      [theme.breakpoints.down("md")]: {
        paddingTop: 23,
        borderRadius: 10,
      },
    },
    sectionHeader: {
      marginBottom: 30,
      maxWidth: "100vw",
      paddingRight: theme.eventPage.horizontalContentPadding,
      [theme.breakpoints.down("md")]: {
        marginBottom: 22,
        paddingRight: 15,
      },
    },
    sectionTitle: {
      flexGrow: 1,
      paddingLeft: theme.eventPage.horizontalContentPadding,
      [theme.breakpoints.down("md")]: {
        paddingLeft: 15,
        paddingRight: 35,
      },
    },
    sectionLink: {
      fontSize: theme.fontSizes.smallFont,
      fontFamily: theme.fontFamily,
      textTransform: "uppercase",
      marginTop: "auto",
      paddingBottom: 4,
      cursor: "pointer",
      textAlign: "right",
    },
    emptySection: {
      paddingTop: 10,
      marginTop: "auto",
      marginBottom: "auto",
    },
  }),
);

export default function EventsPage() {
  const theme = useTheme();
  const classes = useStyles(theme);

  const appTheme = useAppTheme();

  const history = useHistory();
  const dispatch = useDispatch();

  const eventState = useSelector((state: RootState) => {
    return state.eventState;
  });

  const { location, hasValidLocation, hasHadValidLocation } = useAppLocation();

  const sections = eventState.sections;

  // reload on location change
  useEffect(() => {
    dispatch(EventActions.getEvents());
    dispatch(EventActions.getFavorites());
  }, [location?.longitude, location?.latitude, hasValidLocation]);

  const { trackPageView } = useMatomo();

  const analyticsState = useSelector((state: RootState) => {
    return state.analyticsState;
  });

  // Track page view
  React.useEffect(() => {
    trackPageView({
      customDimensions: analyticsState.custom_dimensions,
    });
  }, []);

  useDesktopAdSlot("div-ad-gds-4478-2");
  useMobileAdSlot("div-ad-gds-4479-1");

  const openEventDetails = (event: any) => {
    dispatch(EventActions.selectEvent(event));

    history.push(
      `/events/${event.company_slug}/${event.normalized_title}${appTheme.query}`,
    );
  };

  const heroSection = sections.find((section) => section.type === "hero");

  const favorites = useFavorites();

  const favoritesSection = sections.find(
    (section) => section.category === "my_favorites",
  );

  if (favoritesSection) {
    favoritesSection.events = favorites || [];
  }

  const nonEmptyNotHeroSections = sections.filter(
    (section) =>
      section.events && section.events.length > 0 && section.type == "events",
  );

  const [locationLoading, setLocationLoading] = useState(false);

  const doneLoading =
    (!eventState.loading &&
      eventState.heroImagesLoaded &&
      eventState.sections) ||
    (eventState.sections && eventState.sections.length > 0);

  if (eventState.failed) {
    return (
      <ErrorRetryView
        message={intl.get("events.loading_failed")}
        onRetryClicked={() => {
          eventState.failed = false;
          dispatch(EventActions.getEvents());
          dispatch(EventActions.getFavorites());
        }}
      />
    );
  }

  return (
    <>
      <Box display={"grid"}>
        {!doneLoading && (
          <div
            style={{
              gridRow: 1,
              gridColumn: 1,
              zIndex: 99,
              paddingTop: "25vh",
              width: "100vw",
            }}>
            <LoadingView message={intl.get("events.loading")} />
          </div>
        )}
        <Grid
          style={{
            gridRow: 1,
            gridColumn: 1,
            opacity: doneLoading ? 1 : 0,
            transition: "opacity 300ms 200ms ease-in-out",
          }}
          container
          direction={"column"}
          className={classes.root}
          key={"events_root"}>
          {heroSection && heroSection.events && heroSection.events.length > 0 && (
            <Grid
              item
              className={classes.heroSection}
              key={heroSection.category}>
              <HeroSection
                section={heroSection}
                classes={classes}
                onSelected={openEventDetails}
                onImagesLoaded={() => {
                  eventState.heroImagesLoaded = true;
                }}
              />
            </Grid>
          )}
          {hasHadValidLocation && (
            <Grid item>
              <LocationBar
                key={"locationbar_eventspage"}
                showLoadingProgress
                onLoadingLocationChanged={(loading) =>
                  setLocationLoading(loading)
                }
              />
            </Grid>
          )}

          {!isMobile && (
            <div
              id="div-ad-gds-4478-2"
              style={{
                marginTop: 16,
                marginBottom: 16,
                maxWidth: 728,
                maxHeight: 90,
                marginLeft: "auto",
                marginRight: "auto",
              }}></div>
          )}

          {isMobile && (
            <Grid item>
              <div
                id="div-ad-gds-4479-1"
                style={{
                  marginTop: 16,
                  marginBottom: 16,
                  maxWidth: 300,
                  maxHeight: 600,
                  marginLeft: "auto",
                  marginRight: "auto",
                }}></div>
            </Grid>
          )}

          {(eventState.loading || locationLoading) && (
            <Grid item style={{ marginTop: 0, marginBottom: 15 }}>
              <LoadingView size={"small"} />
            </Grid>
          )}
          {nonEmptyNotHeroSections.length === 0 &&
            !(eventState.loading || locationLoading) &&
            hasValidLocation && (
              <Grid item className={classes.emptySection}>
                <Typography align={"center"}>
                  {intl.get("events.empty")}
                </Typography>
              </Grid>
            )}

          {nonEmptyNotHeroSections.map((section, index) => {
            if (index === 2 && !isMobile) {
              return (
                <CategorySectionGridItemWithAd
                  section={section}
                  onOpen={openEventDetails}
                  classes={classes}
                />
              );
            } else {
              return (
                <CategorySectionGridItem
                  section={section}
                  onOpen={openEventDetails}
                  classes={classes}
                />
              );
            }
          })}
        </Grid>
      </Box>
    </>
  );
}

interface SectionProps {
  section: ISection;
  classes: any;
  onSelected: (event) => void;
}

interface HeroSectionProps extends SectionProps {
  onImagesLoaded: () => void;
}

function HeroSection(props: HeroSectionProps) {
  const { section, onSelected, onImagesLoaded } = props;

  const [loadedEvents, setLoadedEvents] = useState(new Set<string>());

  const handleImageLoaded = (event: IEvent) => {
    const newSet = new Set(loadedEvents).add(event.id);
    setLoadedEvents(newSet);
    if (newSet.size >= 5) {
      onImagesLoaded();
    }
  };

  return (
    <HeroCarousel
      items={section.events}
      autoPage
      numberOfItems={3}
      renderItem={(event: any, isCenter) => {
        return (
          <EventCard
            largeImage={true}
            event={event}
            onSelected={onSelected}
            variant={"hero"}
            hideFavoriteIcon={!isCenter}
            onImageLoaded={handleImageLoaded}
            noLazyLoading
          />
        );
      }}
    />
  );
}

export function CategorySection(props: SectionProps) {
  const { section, classes, onSelected } = props;

  const [canScroll, setCanScroll] = useState(false);

  const appTheme = useAppTheme();
  const user = useUser();

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"row"}
        className={classes.sectionHeader}>
        <Typography className={classes.sectionTitle} variant={"h2"}>
          {Section.getCategoryDisplayName(section.category, user)}
        </Typography>
        {canScroll && (
          <Link
            className={classes.sectionLink}
            color={"primary"}
            href={"/categories/" + section.category + appTheme.query}>
            {intl.get("events.category_show_all")}
          </Link>
        )}
      </Box>
      <EventCarousel
        events={section.events}
        onSelected={onSelected}
        key={section.category}
        hideArrows={isMobile}
        onCanScrollChanged={(carouselCanScroll) =>
          setCanScroll(carouselCanScroll)
        }
      />
    </>
  );
}
